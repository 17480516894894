import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    // {
    //     id: 2,
    //     label: 'MENUITEMS.SETTINGS.TEXT',
    //     icon: 'bx-cog',
    //     subItems: [
    //         {
    //             id: 3,
    //             label: 'MENUITEMS.SETTINGS.LIST.FILEMOVEMENTCONFIG',
    //             link: '/settings/filemovementconfig',
    //             parentId: 2
    //         },
    //         { 
    //             id: 4,
    //             label: 'MENUITEMS.SETTINGS.LIST.SERVICEREGISTER',
    //             link: '/settings/serviceregister',
    //             parentId: 2
    //         },
    //     ]
    // },
    // {
    //     id: 5,
    //     label: 'MENUITEMS.DATAMANAGEMENT.TEXT',
    //     icon: 'bx-data',
    //     subItems: [
    //         {
    //             id: 6,
    //             label: 'MENUITEMS.DATAMANAGEMENT.LIST.AUDITLOG',
    //             link: '/datamanagement/auditlog',
    //             parentId: 5
    //         }
    //     ]
    // },
    // {
    //     id: 7,
    //     label: 'MENUITEMS.MASTERS.TEXT',
    //     icon: 'bx-server',
    //     link: '/masters',
    //     subItems: [
    //         {
    //             id: 8,
    //             label: 'MENUITEMS.MASTERS.LIST.LEVEL1.1',
    //             parentId: 7,
    //             subItems: [
    //                 {
    //                     id: 9,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.LEVEL2.1',
    //                     link: '/masters/assetmasters/assetcategory',
    //                     parentId: 8,
    //                 },
    //                 {
    //                     id: 10,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.LEVEL2.2',
    //                     link: '/masters/assetmasters/assetregister',
    //                     parentId: 8,
    //                 }
    //             ]
    //         },
    //         {
    //             id: 11,
    //             label: 'MENUITEMS.MASTERS.LIST.LEVEL3.1',
    //             parentId: 7,
    //             subItems: [
    //                 {
    //                     id: 12,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL3.LEVEL4.1',
    //                     link: '/masters/financemasters/accountcategory',
    //                     parentId: 11,
    //                 },
    //                 {
    //                     id: 13,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL3.LEVEL4.2',
    //                     link: '/masters/financemasters/accountgroup',
    //                     parentId: 11,
    //                 },
    //                 {
    //                     id: 14,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL3.LEVEL4.3',
    //                     link: '/masters/financemasters/accounthead',
    //                     parentId: 11,
    //                 },
    //                 {
    //                     id: 15,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL3.LEVEL4.4',
    //                     link: '/masters/financemasters/bank',
    //                     parentId: 11,
    //                 },
    //                 {
    //                     id: 16,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL3.LEVEL4.5',
    //                     link: '/masters/financemasters/bankaccounttype',
    //                     parentId: 11,
    //                 },
    //                 {
    //                     id: 17,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL3.LEVEL4.6',
    //                     link: '/masters/financemasters/bankbranch',
    //                     parentId: 11,
    //                 },
    //                 {
    //                     id: 18,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL3.LEVEL4.7',
    //                     link: '/masters/financemasters/investmentcategory',
    //                     parentId: 11,
    //                 },
    //                 {
    //                     id: 19,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL3.LEVEL4.8',
    //                     link: '/masters/financemasters/investmenttype',
    //                     parentId: 11,
    //                 },
    //                 {
    //                     id: 20,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL3.LEVEL4.9',
    //                     link: '/masters/financemasters/incometaxsavingscheme',
    //                     parentId: 11,
    //                 }
    //             ],
    //         },
    //         {
    //             id: 21,
    //             label: 'MENUITEMS.MASTERS.LIST.LEVEL5.1',
    //             parentId: 7,
    //             subItems: [
    //                 {
    //                     id: 22,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL5.LEVEL6.1',
    //                     link: '/masters/generalmasters/helpdeskissuereason',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 23,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL5.LEVEL6.2',
    //                     link: '/masters/generalmasters/helpdesktypemaster',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 24,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL5.LEVEL6.3',
    //                     link: '/masters/generalmasters/area',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 25,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL5.LEVEL6.4',
    //                     link: '/masters/generalmasters/village',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 26,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL5.LEVEL6.5',
    //                     link: '/masters/generalmasters/documenttype',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 27,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL5.LEVEL6.6',
    //                     link: '/masters/generalmasters/gender',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 28,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL5.LEVEL6.7',
    //                     link: '/masters/generalmasters/city',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 29,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL5.LEVEL6.8',
    //                     link: '/masters/generalmasters/country',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 30,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL5.LEVEL6.9',
    //                     link: '/masters/generalmasters/district',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 31,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL5.LEVEL6.10',
    //                     link: '/masters/generalmasters/emailtemplate',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 32,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL5.LEVEL6.11',
    //                     link: '/masters/generalmasters/nationality',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 33,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL5.LEVEL6.12',
    //                     link: '/masters/generalmasters/state',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 34,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL5.LEVEL6.13',
    //                     link: '/masters/generalmasters/taluk',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 35,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL5.LEVEL6.14',
    //                     link: '/masters/generalmasters/ticketcategory',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 36,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL5.LEVEL6.15',
    //                     link: '/masters/generalmasters/messagebundle',
    //                     parentId: 21,
    //                 }
    //             ],
    //         },
    //         {
    //             id: 37,
    //             label: 'MENUITEMS.MASTERS.LIST.LEVEL7.1',
    //             parentId: 7,
    //             subItems: [
    //                 {
    //                     id: 38,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.1',
    //                     link: '/masters/operationalmasters/businesstype',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 39,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.2',
    //                     link: '/masters/operationalmasters/circle',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 40,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.3',
    //                     link: '/masters/operationalmasters/couponregister',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 41,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.4',
    //                     link: '/masters/operationalmasters/creditsalesrequest',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 42,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.5',
    //                     link: '/masters/operationalmasters/customer',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 43,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.6',
    //                     link: '/masters/operationalmasters/customertype',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 44,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.7',
    //                     link: '/masters/operationalmasters/discountrebate',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 45,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.8',
    //                     link: '/masters/operationalmasters/entitysupplierproductlink',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 46,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.9',
    //                     link: '/masters/operationalmasters/exhibition',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 47,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.10',
    //                     link: '/masters/operationalmasters/festival',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 48,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.11',
    //                     link: '/masters/operationalmasters/festivalperiod',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 49,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.12',
    //                     link: '/masters/operationalmasters/inventorycontrol',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 50,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.13',
    //                     link: '/masters/operationalmasters/kntplan',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 51,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.14',
    //                     link: '/masters/operationalmasters/kntplandenomination',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 52,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.15',
    //                     link: '/masters/operationalmasters/loomtype',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 53,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.16',
    //                     link: '/masters/operationalmasters/media',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 54,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.17',
    //                     link: '/masters/operationalmasters/modeofenquiry',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 55,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.18',
    //                     link: '/masters/operationalmasters/organization',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 56,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.19',
    //                     link: '/masters/operationalmasters/organizationtype',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 57,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.20',
    //                     link: '/masters/operationalmasters/productcategory',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 58,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.21',
    //                     link: '/masters/operationalmasters/productcategorygroup',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 59,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.22',
    //                     link: '/masters/operationalmasters/productdesign',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 60,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.23',
    //                     link: '/masters/operationalmasters/productgroup',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 61,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.24',
    //                     link: '/masters/operationalmasters/productvariety',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 62,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.25',
    //                     link: '/masters/operationalmasters/productvarietytax',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 63,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.26',
    //                     link: '/masters/operationalmasters/profile',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 64,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.27',
    //                     link: '/masters/operationalmasters/profitmargin',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 65,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.28',
    //                     link: '/masters/operationalmasters/silkproductcosting',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 66,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.29',
    //                     link: '/masters/operationalmasters/societyclass',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 67,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.30',
    //                     link: '/masters/operationalmasters/supplier',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 68,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.31',
    //                     link: '/masters/operationalmasters/suppliertype',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 69,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.32',
    //                     link: '/masters/operationalmasters/tendercategory',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 70,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.33',
    //                     link: '/masters/operationalmasters/tendertype',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 71,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.34',
    //                     link: '/masters/operationalmasters/transport',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 72,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.35',
    //                     link: '/masters/operationalmasters/uom',
    //                     parentId: 37,
    //                 },
    //                 {
    //                     id: 73,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL7.LEVEL8.36',
    //                     link: '/masters/operationalmasters/weaversbenefitschemes',
    //                     parentId: 37,
    //                 }
    //             ],
    //         },

    /////////////
             {
                id: 74,
                label: 'MENUITEMS.MASTERS.LIST.LEVEL9.1',
                parentId: 7,
                subItems: [
    ////////////
    //                 {
    //                     id: 75,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.1',
    //                     link: '/masters/personnelmasters/advancetype',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 76,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.2',
    //                     link: '/masters/personnelmasters/biometricdevice',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 77,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.3',
    //                     link: '/masters/personnelmasters/bloodgroup',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 78,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.4',
    //                     link: '/masters/personnelmasters/cadre',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 79,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.5',
    //                     link: '/masters/personnelmasters/caste',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 80,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.6',
    //                     link: '/masters/personnelmasters/communalrosterregister',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 81,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.7',
    //                     link: '/masters/personnelmasters/community',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 82,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.8',
    //                     link: '/masters/personnelmasters/complainttype',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 83,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.9',
    //                     link: '/masters/personnelmasters/court',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 84,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.10',
    //                     link: '/masters/personnelmasters/department',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 85,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.11',
    //                     link: '/masters/personnelmasters/designation',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 86,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.12',
    //                     link: '/masters/personnelmasters/educationalqualification',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 87,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.13',
    //                     link: '/masters/personnelmasters/employeetype',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 88,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.14',
    //                     link: '/masters/personnelmasters/enquiryofficer',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 89,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.15',
    //                     link: '/masters/personnelmasters/fuelcoupon',
    //                     parentId: 74,
    //                 },

    ///////////
                    {
                        id: 90,
                        label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.16',
                        link: '/masters/personnelmasters/holiday',
                        parentId: 74,
                    }]},
    //////////
                    
    //                 {
    //                     id: 91,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.17',
    //                     link: '/masters/personnelmasters/insurance',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 92,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.18',
    //                     link: '/masters/personnelmasters/leaveeligibility',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 93,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.19',
    //                     link: '/masters/personnelmasters/leavetype',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 94,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.20',
    //                     link: '/masters/personnelmasters/loan',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 95,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.21',
    //                     link: '/masters/personnelmasters/maritalstatus',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 96,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.22',
    //                     link: '/masters/personnelmasters/payscale',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 97,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.23',
    //                     link: '/masters/personnelmasters/petitionertype',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 98,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.24',
    //                     link: '/masters/personnelmasters/petitionsource',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 99,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.25',
    //                     link: '/masters/personnelmasters/petitiontype',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 100,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.26',
    //                     link: '/masters/personnelmasters/priority',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 101,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.27',
    //                     link: '/masters/personnelmasters/religion',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 102,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.28',
    //                     link: '/masters/personnelmasters/sanctionedstrength',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 103,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.29',
    //                     link: '/masters/personnelmasters/section',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 104,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.30',
    //                     link: '/masters/personnelmasters/staffeligibility',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 105,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.31',
    //                     link: '/masters/personnelmasters/tapaldeliverytype',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 106,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.32',
    //                     link: '/masters/personnelmasters/tapalfor',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 107,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.33',
    //                     link: '/masters/personnelmasters/tapalsendertype',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 108,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.34',
    //                     link: '/masters/personnelmasters/trainingtype',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 109,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.35',
    //                     link: '/masters/personnelmasters/vehicle',
    //                     parentId: 74,
    //                 },
    //                 {
    //                     id: 110,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL9.LEVEL10.36',
    //                     link: '/masters/personnelmasters/venue',
    //                     parentId: 74,
    //                 }
    //             ],
    //         }
    //     ]
    // },
    // {
    //     id: 111,
    //     label: 'MENUITEMS.USERMANAGEMENT.TEXT',
    //     icon: 'bxs-user',
        
    //     subItems: [
    //         {
    //             id: 112,
    //             label: 'MENUITEMS.USERMANAGEMENT.LIST.USER',
    //             link: '/usermanagement/user',
    //             parentId: 111
    //         },
    //         {
    //             id: 113,
    //             label: 'MENUITEMS.USERMANAGEMENT.LIST.ROLE',
    //             link: '/usermanagement/role',
    //             parentId: 111
    //         },
    //     ]
    // },
    // {
    //     id:114,
    //     label: 'MENUITEMS.PERSONNEL.TEXT',
    //     link: '/personnel',
    //     icon: 'bxs-user',
    //     subItems: [
    //         {
    //             id: 115,
    //             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.1',
    //             parentId: 114,
    //             subItems: [
    //                 {
    //                     id: 116,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.1',
    //                     parentId: 115,
    //                     subItems: [
    //                         {
    //                             id: 117,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.1',
    //                             parentId: 116,
    //                             subItems: [
    //                                 {
    //                                     id: 118,
    //                                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.LEVEL4.1',
    //                                     link: '/personnel/humanresource/recruitmentprocess/permanentrecruitment/staffeligibilitystatement',
    //                                     parentId: 117
    //                                 },
    //                                 {
    //                                     id: 119,
    //                                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.LEVEL4.2',
    //                                     link: '/personnel/humanresource/recruitmentprocess/permanentrecruitment/rosterreservation',
    //                                     parentId: 117
    //                                 },
    //                                 {
    //                                     id: 120,
    //                                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.LEVEL4.3',
    //                                     link: '/personnel/humanresource/recruitmentprocess/permanentrecruitment/jobadvertisement',
    //                                     parentId: 117
    //                                 },
    //                                 {
    //                                     id: 121,
    //                                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.LEVEL4.4',
    //                                     link: '/personnel/humanresource/recruitmentprocess/permanentrecruitment/jobapplication',
    //                                     parentId: 117
    //                                 },
    //                                 {
    //                                     id: 122,
    //                                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.LEVEL4.5',
    //                                     link: '/personnel/humanresource/recruitmentprocess/permanentrecruitment/examcenter',
    //                                     parentId: 117
    //                                 },
    //                                 {
    //                                     id: 123,
    //                                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.LEVEL4.6',
    //                                     link: '/personnel/humanresource/recruitmentprocess/permanentrecruitment/totalnominal',
    //                                     parentId: 117
    //                                 },
    //                                 {
    //                                     id: 124,
    //                                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.LEVEL4.7',
    //                                     link: '/personnel/humanresource/recruitmentprocess/permanentrecruitment/hallticket',
    //                                     parentId: 117
    //                                 },
    //                                 {
    //                                     id: 125,
    //                                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.LEVEL4.8',
    //                                     link: '/personnel/humanresource/recruitmentprocess/permanentrecruitment/writtenexamination',
    //                                     parentId: 117
    //                                 },
    //                                 {
    //                                     id: 126,
    //                                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.LEVEL4.9',
    //                                     link: '/personnel/humanresource/recruitmentprocess/permanentrecruitment/oraltestselection',
    //                                     parentId: 117
    //                                 },
    //                                 {
    //                                     id: 127,
    //                                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.LEVEL4.10',
    //                                     link: '/personnel/humanresource/recruitmentprocess/permanentrecruitment/oraltestvenue',
    //                                     parentId: 117
    //                                 },
    //                                 {
    //                                     id: 128,
    //                                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.LEVEL4.11',
    //                                     link: '/personnel/humanresource/recruitmentprocess/permanentrecruitment/callletter',
    //                                     parentId: 117
    //                                 },
    //                                 {
    //                                     id: 129,
    //                                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.LEVEL4.12',
    //                                     link: '/personnel/humanresource/recruitmentprocess/permanentrecruitment/oraltestmark',
    //                                     parentId: 117
    //                                 },
    //                                 {
    //                                     id: 130,
    //                                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.LEVEL4.13',
    //                                     link: '/personnel/humanresource/recruitmentprocess/permanentrecruitment/consolidatedmark',
    //                                     parentId: 117
    //                                 },
    //                                 {
    //                                     id: 131,
    //                                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.LEVEL4.14',
    //                                     link: '/personnel/humanresource/recruitmentprocess/permanentrecruitment/appointmentorder',
    //                                     parentId: 117
    //                                 }
    //                             ]
    //                         },
    //                         {
    //                             id: 132,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.2',
    //                             link: '/personnel/humanresource/recruitmentprocess/temporaryengagement',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 133,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL3.3',
    //                             link: '/personnel/humanresource/recruitmentprocess/compassionateappointment',
    //                             parentId: 115
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     id: 134,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.2',
    //                     link: '/personnel/humanresource/empserviceregister',
    //                     parentId: 115,
    //                 },
    //                 {
    //                     id: 135,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.3',
    //                     link: '/personnel/humanresource/retiredempregister',
    //                     parentId: 115,
    //                 },
    //                 {
    //                     id: 136,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.4',
    //                     link: '/personnel/humanresource/emppayrollregister',
    //                     parentId: 115,
    //                 },
    //                 {
    //                     id: 137,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.5',
    //                     link: '/personnel/humanresource/empserviceregister',
    //                     parentId: 115,
    //                 },
    //                 {
    //                     id: 138,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.6',
    //                     link: '/personnel/humanresource/retiredempregister',
    //                     parentId: 115,
    //                 },
    //                 {
    //                     id: 139,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.7',
    //                     link: '/personnel/humanresource/attendance',
    //                     parentId: 115,
    //                     subItems: [
    //                         {
    //                             id: 140,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL5.1',
    //                             link: '/personnel/humanresource/attendance/manualattendance',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 141,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL5.2',
    //                             link: '/personnel/humanresource/attendance/biometricattendance',
    //                             parentId: 115
    //                         }
    //                     ]
    //                 },
                    {
                        id: 142,
                        label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.8',
                        parentId: 115,
                        subItems: [
                            {
                                id: 143,
                                label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL6.1',
                                link: '/personnel/humanresource/leavemanagement/leaverequest',
                                parentId: 115
                            }
                        ]
                    },
    //                 {
    //                     id: 144,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.9',
    //                     parentId: 115,
    //                     subItems: [
    //                         {
    //                             id: 145,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL7.1',
    //                             link: '/personnel/humanresource/payroll/employeepaymentdetails',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 146,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL7.2',
    //                             link: '/personnel/humanresource/payroll/incometaxworksheet',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 147,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL7.3',
    //                             link: '/personnel/humanresource/payroll/payslipgeneration',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 148,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL7.4',
    //                             link: '/personnel/humanresource/payroll/paymaster',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 149,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL7.5',
    //                             link: '/personnel/humanresource/payroll/payrollconfiguration',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 150,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL7.6',
    //                             link: '/personnel/humanresource/payroll/additionalearnings',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 151,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL7.7',
    //                             link: '/personnel/humanresource/payroll/loandisbursement',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 152,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL7.8',
    //                             link: '/personnel/humanresource/payroll/payrollverification',
    //                             parentId: 115
    //                         },
    //                     ]
    //                 },
    //                 {
    //                     id: 153,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.10',
    //                     link: '/personnel/humanresource/tabill',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 154,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.11',
    //                     link: '/personnel/humanresource/promotion',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 155,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.12',
    //                     link: '/personnel/humanresource/loanandadvance',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 156,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.13',
    //                     link: '/personnel/humanresource/transferordeputation',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 157,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.14',
    //                     link: '/personnel/humanresource/additionalcharge',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 158,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.15',
    //                     link: '/personnel/humanresource/insurance',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 159,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.16',
    //                     link: '/personnel/humanresource/joiningchecklist',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 160,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.17',
    //                     link: '/personnel/humanresource/interchange',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 161,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.18',
    //                     link: '/personnel/humanresource/leavetravelconcession',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 162,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.19',
    //                     link: '/personnel/humanresource/employeeregularization',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 163,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.20',
    //                     link: '/personnel/humanresource/confirmationpostponed',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 164,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.21',
    //                     link: '/personnel/humanresource/increment',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 165,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.22',
    //                     link: '/personnel/humanresource/voluntarypfprocess',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 166,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.23',
    //                     link: '/personnel/humanresource/resignation',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 167,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.24',
    //                     link: '/personnel/humanresource/retirement',
    //                     parentId: 115,
    //                     subItems: [
    //                         {
    //                             id: 168,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL8.1',
    //                             link: '/personnel/humanresource/retirement/normalretirement',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 169,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL8.2',
    //                             link: '/personnel/humanresource/retirement/voluntaryretirement',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 170,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL8.3',
    //                             link: '/personnel/humanresource/retirement/compulsoryretirement',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 171,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL1.LEVEL2.LEVEL8.4',
    //                             link: '/personnel/humanresource/retirement/empdeathregistration',
    //                             parentId: 115
    //                         },
    //                     ]
    //                 }
    //             ]
    //         },
    //         {
    //             id: 172,
    //             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.1',
    //             link: '/personnel/admin',
    //             parentId: 114,
    //             subItems: [
    //                 {
    //                     id: 173,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.1',
    //                     link: '/personnel/admin/tapal',
    //                     parentId: 115,
    //                     subItems: [
    //                         {
    //                             id: 174,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.LEVEL11.1',
    //                             link: '/personnel/admin/tapal/incomingtapal',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 175,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.LEVEL11.2',
    //                             link: '/personnel/admin/tapal/outgoingtapal',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 176,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.LEVEL11.3',
    //                             link: '/personnel/admin/tapal/filenumbering',
    //                             parentId: 115
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     id: 177,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.2',
    //                     link: '/personnel/admin/vehiclemanagement',
    //                     parentId: 115,
    //                     subItems: [
    //                         {
    //                             id: 178,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.LEVEL12.1',
    //                             link: '/personnel/admin/vehiclemanagement/vehicledeparture',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 179,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.LEVEL12.2',
    //                             link: '/personnel/admin/vehiclemanagement/vehiclearrival',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 180,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.LEVEL12.3',
    //                             link: '/personnel/admin/vehiclemanagement/fuelfillingregister',
    //                             parentId: 115
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     id: 181,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.3',
    //                     link: '/personnel/admin/training',
    //                     parentId: 115,
    //                     subItems: [
    //                         {
    //                             id: 182,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.LEVEL13.1',
    //                             link: '/personnel/admin/training/studenttraining',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 183,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.LEVEL13.2',
    //                             link: '/personnel/admin/training/internaltraining',
    //                             parentId: 115
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     id: 184,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.4',
    //                     link: '/personnel/admin/meeting',
    //                     parentId: 115,
    //                     subItems: [
    //                         {
    //                             id: 185,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.LEVEL14.1',
    //                             link: '/personnel/admin/meeting/meetingrequest',
    //                             parentId: 115
    //                         },
    //                         {
    //                             id: 186,
    //                             label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.LEVEL14.2',
    //                             link: '/personnel/admin/meeting/meetingreport',
    //                             parentId: 115
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     id: 187,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.5',
    //                     link: '/personnel/admin/filemovement',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 188,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.6',
    //                     link: '/personnel/admin/disciplinaryaction',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 189,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.7',
    //                     link: '/personnel/admin/suspensiondetails',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 190,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.8',
    //                     link: '/personnel/admin/courtcase',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 191,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.9',
    //                     link: '/personnel/admin/circular',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 192,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.10',
    //                     link: '/personnel/admin/claimforexpo',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 193,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.11',
    //                     link: '/personnel/admin/petition',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 194,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL9.LEVEL10.12',
    //                     link: '/personnel/admin/policynote',
    //                     parentId: 115
    //                 }
    //             ]
    //         },
     {
        id: 195,
        label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.1',
        link: '/personnel/employeeselfservice',
        parentId: 114,
         subItems: [
    //                 {
    //                     id: 196,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.1',
    //                     link: '/personnel/employeeselfservice/employeedetail',
    //                     parentId: 115
    //                 },
                    {
                        id: 196,
                        label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.2',
                        link: '/personnel/employeeselfservice/applyleave',
                        parentId: 115
                    },
    //                 {
    //                     id: 196,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.3',
    //                     link: '/personnel/employeeselfservice/fbfcontribution',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 196,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.4',
    //                     link: '/personnel/employeeselfservice/flagdayfundcontribution',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 196,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.5',
    //                     link: '/personnel/employeeselfservice/interchangerequest',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 196,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.6',
    //                     link: '/personnel/employeeselfservice/aditionalcharge',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 196,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.7',
    //                     link: '/personnel/employeeselfservice/insurance',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 196,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.8',
    //                     link: '/personnel/employeeselfservice/loadadvance',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 196,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.9',
    //                     link: '/personnel/employeeselfservice/loadinformation',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 196,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.10',
    //                     link: '/personnel/employeeselfservice/suretyacceptance',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 196,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.11',
    //                     link: '/personnel/employeeselfservice/transfer',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 196,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.12',
    //                     link: '/personnel/employeeselfservice/promotion',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 196,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.13',
    //                     link: '/personnel/employeeselfservice/trainingrequest',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 196,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.14',
    //                     link: '/personnel/employeeselfservice/leavetravelconcession',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 196,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.15',
    //                     link: '/personnel/employeeselfservice/payslip',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 196,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.16',
    //                     link: '/personnel/employeeselfservice/voluntaryresignationrequest',
    //                     parentId: 115
    //                 },
    //                 {
    //                     id: 196,
    //                     label: 'MENUITEMS.PERSONNEL.LIST.LEVEL15.LEVEL16.17',
    //                     link: '/personnel/employeeselfservice/voluntarypfrequest',
    //                     parentId: 115
    //                 },
    //             ]
    //         }
    //    ]
    // },
    // {
    //     id: 116,
    //     label: 'MENUITEMS.OPERATIONAL.TEXT',
    //     icon: 'bxs-grid',
    //     subItems: [
    //         {
    //             id: 117,
    //             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.1',
    //             // link: '/operational/',
    //             parentId: 116,
    //             subItems: [
    //                 {
    //                     id: 118,
    //                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL2.1',
    //                     // link: '',
    //                     parentId: 117,
    //                     subItems: [
    //                         {
    //                             id: 119,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL2.LEVEL3.1',
    //                             link: '/operational/productionplanning/retailproduction/initialproductionplan',
    //                             parentId: 118
    //                         },
    //                         {
    //                             id: 120,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL2.LEVEL3.2',
    //                             link: '/operational/productionplanning/retailproduction/monthwiseplan/',
    //                             parentId: 118
    //                         },
    //                         {
    //                             id: 121,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL2.LEVEL3.3',
    //                             link: '/operational/productionplanning/retailproduction/showroomplan',
    //                             parentId: 118
    //                         },
    //                         {
    //                             id: 122,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL2.LEVEL3.4',
    //                             link: '/operational/productionplanning/retailproduction/regionalofficeplan',
    //                             parentId: 118
    //                         },
    //                         {
    //                             id: 123,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL2.LEVEL3.5',
    //                             link: '/operational/productionplanning/retailproduction/finalplan',
    //                             parentId: 118
    //                         },
    //                         {
    //                             id: 124,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL2.LEVEL3.6',
    //                             link: '/operational/productionplanning/retailproduction/productionplanho',
    //                             parentId: 118
    //                         },
    //                         {
    //                             id: 125,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL2.LEVEL3.7',
    //                             link: '/operational/productionplanning/retailproduction/productionplanro',
    //                             parentId: 118
    //                         },
    //                         {
    //                             id: 126,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL2.LEVEL3.8',
    //                             link: '/operational/productionplanning/retailproduction/productionplansr',
    //                             parentId: 118
    //                         },
    //                         {
    //                             id: 127,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL2.LEVEL3.9',
    //                             link: '/operational/productionplanning/retailproduction/monthwiseprocurementplan',
    //                             parentId: 118
    //                         },
    //                         {
    //                             id: 128,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL2.LEVEL3.10',
    //                             link: '/operational/productionplanning/retailproduction/dnpprocurementplan',
    //                             parentId: 118
    //                         },
    //                         {
    //                             id: 129,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL2.LEVEL3.11',
    //                             link: '/operational/productionplanning/retailproduction/societywiseplan',
    //                             parentId: 118
    //                         },
    //                         {
    //                             id: 130,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL2.LEVEL3.12',
    //                             link: 'operational/productionplanning/retailproduction/retailprocurementorder',
    //                             parentId: 118
    //                         },
    //                         {
    //                             id: 131,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL2.LEVEL3.13',
    //                             link: 'operational/productionplanning/retailproduction/purchaseorderdetails',
    //                             parentId: 118
    //                         },
    //                     ]
    //                 },
    //                 {
    //                     id: 128,
    //                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL4.1',
    //                     // link: '/operational/',
    //                     parentId: 116,
    //                     subItems: [
    //                         {
    //                             id: 129,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL4.LEVEL5.1',
    //                             link: '/operational/',
    //                             parentId: 128,
    //                         },
    //                         {
    //                             id: 130,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL4.LEVEL5.2',
    //                             link: '/operational/',
    //                             parentId: 128,
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     id: 131,
    //                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL6.1',
    //                     // link: '/operational/',
    //                     parentId: 116,
    //                     subItems: [
    //                         {
    //                             id: 132,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL6.LEVEL7.1',
    //                             link: '/operational/',
    //                             parentId: 128,
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     id: 133,
    //                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL1.LEVEL8.1',
    //                     link: '/operational/productionplanning/additionalproductionplan',
    //                     parentId: 116
    //                 }
    //             ]
    //         },
    //         {
    //             id: 134,
    //             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.1',
    //             // link: '',
    //             parentId: 116,
    //             subItems: [
    //                 {
    //                     id: 135,
    //                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL10.1',
    //                     link: '/operational/',
    //                     parentId: 134
    //                 },
    //                 {
    //                     id: 136,
    //                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL11.1',
    //                     link: '/operational/',
    //                     parentId: 134,
    //                     subItems: [
    //                         {
    //                             id: 135,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL11.LEVEL12.1',
    //                             link: '/operational/',
    //                             parentId: 134
    //                         },
    //                         {
    //                             id: 135,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL11.LEVEL12.2',
    //                             link: '/operational/',
    //                             parentId: 134
    //                         },
    //                         {
    //                             id: 135,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL11.LEVEL12.3',
    //                             link: '/operational/',
    //                             parentId: 134
    //                         },
    //                         {
    //                             id: 135,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL11.LEVEL12.4',
    //                             link: '/operational/',
    //                             parentId: 134
    //                         },
    //                     ]
    //                 },
    //                 {
    //                     id: 136,
    //                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL13.1',
    //                     link: '/operational/',
    //                     parentId: 134,
    //                     subItems: [
    //                         {
    //                             id: 135,
    //                             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL13.LEVEL14.1',
    //                             link: '/operational/',
    //                             parentId: 134,
    //                             subItems: [
    //                                 {
    //                                     id: 135,
    //                                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL13.LEVEL14.LEVEL15.1',
    //                                     link: '/operational/',
    //                                     parentId: 134
    //                                 },
    //                                 {
    //                                     id: 135,
    //                                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL13.LEVEL14.LEVEL15.2',
    //                                     link: '/operational/',
    //                                     parentId: 134
    //                                 },
    //                                 {
    //                                     id: 135,
    //                                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL13.LEVEL14.LEVEL15.3',
    //                                     link: '/operational/',
    //                                     parentId: 134
    //                                 }
    //                             ]
    //                         },
    //                         // {
    //                         //     id: 135,
    //                         //     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL11.LEVEL12.2',
    //                         //     link: '/operational/',
    //                         //     parentId: 134
    //                         // },
    //                         // {
    //                         //     id: 135,
    //                         //     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL11.LEVEL12.3',
    //                         //     link: '/operational/',
    //                         //     parentId: 134
    //                         // },
    //                         // {
    //                         //     id: 135,
    //                         //     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL11.LEVEL12.4',
    //                         //     link: '/operational/',
    //                         //     parentId: 134
    //                         // },
    //                     ]
    //                 }
                ]
            },
    //         {
    //             id: 136,
    //             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL11.1',
    //             link: '/operational/',
    //             parentId: 116,
    //             subItems: [
    //                 {
    //                     id: 137,
    //                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL11.LEVEL12.1',
    //                     link: '/operational/',
    //                     parentId: 134
    //                 },
    //                 {
    //                     id: 138,
    //                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL11.LEVEL12.2',
    //                     link: '/operational/',
    //                     parentId: 134
    //                 },
    //                 {
    //                     id: 139,
    //                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL11.LEVEL12.3',
    //                     link: '/operational/',
    //                     parentId: 134
    //                 },
    //                 {
    //                     id: 140,
    //                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL11.LEVEL12.4',
    //                     link: '/operational/',
    //                     parentId: 134
    //                 }
    //             ]
    //         },
    //         {
    //             id: 141,
    //             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL13.1',
    //             link: '/operational/',
    //             parentId: 116,
    //             subItems: [
    //                 {
    //                     id: 142,
    //                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL13.LEVEL14.1',
    //                     link: '/operational/',
    //                     parentId: 134
    //                 },
    //                 {
    //                     id: 143,
    //                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL13.LEVEL14.LEVEL15.1',
    //                     link: '/operational/',
    //                     parentId: 134
    //                 },
    //                 {
    //                     id: 144,
    //                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL13.LEVEL14.LEVEL15.2',
    //                     link: '/operational/',
    //                     parentId: 134
    //                 },
    //                 {
    //                     id: 145,
    //                     label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL13.LEVEL14.LEVEL15.3',
    //                     link: '/operational/',
    //                     parentId: 134
    //                 }
    //             ]
    //         },
    //         {
    //             id: 146,
    //             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL13.LEVEL16.1',
    //             link: '/operational/',
    //             parentId: 116,
    //         },
    //         {
    //             id: 146,
    //             label: 'MENUITEMS.OPERATIONAL.LIST.LEVEL9.LEVEL13.LEVEL16.2',
    //             link: '/operational/',
    //             parentId: 116,
    //         }
    //     ]
    // },
    // {
    //     id: 131,
    //     label: 'MENUITEMS.WEAVERS.TEXT',
    //     icon: 'bxs-user',
        
    //     subItems: [
    //         {
    //             id: 110,
    //             label: 'MENUITEMS.WEAVERS.LIST.PROCUREMENTCOSTINGSOCIETY',
    //             link: '/weavers/procurementcosting/list',
    //             parentId: 131
    //         },
    //         {
    //             id: 111,
    //             label: 'MENUITEMS.WEAVERS.LIST.SOCIETYPRODUCTAPPRAISAL',
    //             link: '/weavers/societyproductappraisal/list',
    //             parentId: 131
    //         },
    //         {
    //             id: 112,
    //             label: 'MENUITEMS.WEAVERS.LIST.SUPPLYRATECONFIRMATION',
    //             link: '/weavers/supplyrateconfirmation/list',
    //             parentId: 131
    //         },
    //         {
    //             id: 6,
    //             label: 'MENUITEMS.WEAVERS.LIST.LEVEL1.1',
    //             parentId: 5,
    //             subItems: [
    //                 {
    //                     id: 7,
    //                     label: 'MENUITEMS.WEAVERS.LIST.LEVEL1.LEVEL2.1',
    //                     link: '/weavers/requestforsocialenrollment/list',
    //                     parentId: 6,
    //                 },
    //                 {
    //                     id: 8,
    //                     label: 'MENUITEMS.WEAVERS.LIST.LEVEL1.LEVEL2.2',
    //                     link: '/weavers/societyenrollment/list',
    //                     parentId: 6,
    //                 },
    //                 {
    //                     id: 9,
    //                     label: 'MENUITEMS.WEAVERS.LIST.LEVEL1.LEVEL2.3',
    //                     link: '/weavers/fieldverification/list',
    //                     parentId: 6,
    //                 },
    //                 {
    //                     id: 10,
    //                     label: 'MENUITEMS.WEAVERS.LIST.LEVEL1.LEVEL2.4',
    //                     link: '/weavers/headofficeapproval/list',
    //                     parentId: 6,
    //                 },
    //                 {
    //                     id: 11,
    //                     label: 'MENUITEMS.WEAVERS.LIST.LEVEL1.LEVEL2.5',
    //                     link: '/weavers/boardapproval/list',
    //                     parentId: 6,
    //                 },
    //                 {
    //                     id: 12,
    //                     label: 'MENUITEMS.WEAVERS.LIST.LEVEL1.LEVEL2.6',
    //                     link: '/weavers/codeallotment/list',
    //                     parentId: 6,
    //                 }
    //             ]
    //         },
    //         {
    //             id: 113,
    //             label: 'MENUITEMS.WEAVERS.LIST.SOCIETYSTOCKOUTWARDS',
    //             link: '/weavers/societystockoutwards/list',
    //             parentId: 131
    //         },
    //         {
    //             id: 114,
    //             label: 'MENUITEMS.WEAVERS.LIST.SOCIETYINVOICELIST',
    //             link: '/weavers/societyinvoicelist/list',
    //             parentId: 131
    //         },
           
    //     ]     
    // },
    // {
    //     id: 109,
    //     label: 'MENUITEMS.ASSET MANAGEMENT.TEXT',
    //     link: '/assetmanagement',
    //     icon: 'bxs-bed',
    //     subItems: [
    //         {
    //             id: 110,
    //             label: 'MENUITEMS.ASSET MANAGEMENT.LIST.MODERNIZATION.TEXT',
    //             parentId: 109,
    //             subItems: [
    //                 {
    //                     id: 111,
    //                     label: 'MENUITEMS.ASSET MANAGEMENT.LIST.MODERNIZATION.LIST.REQUEST FOR MODERNIZATION/ CONSTRUCTION/SUPPLEMENTARY WORK',
    //                     link: '/assetmanagement/modernization/requestformodernizationwork',
    //                     parentId: 110,
    //                 },
    //                 {
    //                     id: 112,
    //                     label: 'MENUITEMS.ASSET MANAGEMENT.LIST.MODERNIZATION.LIST.ESTIMATION OF MODERNIZATION',
    //                     link: '/assetmanagement/modernization/estimationofmodernization',
    //                     parentId: 110,
    //                 },
    //                 {
    //                     id: 113,
    //                     label: 'MENUITEMS.ASSET MANAGEMENT.LIST.MODERNIZATION.LIST.SUBMISSION OF REPORT',
    //                     link: '/assetmanagement/modernization/submissionofreport',
    //                     parentId: 110,
    //                 }
    //             ]
    //         },
    //         {
    //             id: 114,
    //             label: 'MENUITEMS.ASSET MANAGEMENT.LIST.ASSET REQUEST',
    //             link: '/assetmanagement/assetrequest',
    //             parentId: 109,
    //         },
    //         {
    //             id: 115,
    //             label: 'MENUITEMS.ASSET MANAGEMENT.LIST.ASSET TRANSFER',
    //             link: '/assetmanagement/assettransfer',
    //             parentId: 109,
    //         },
    //         {
    //             id: 116,
    //             label: 'MENUITEMS.ASSET MANAGEMENT.LIST.ASSET ALLOCATION',
    //             link: '/assetmanagement/assetallocation',
    //             parentId: 109,
    //         },
    //         {
    //             id: 117,
    //             label: 'MENUITEMS.ASSET MANAGEMENT.LIST.ASSET DEPRECIATION',
    //             link: '/assetmanagement/assetdepreciation',
    //             parentId: 109,
    //         },
    //         {
    //             id: 118,
    //             label: 'MENUITEMS.ASSET MANAGEMENT.LIST.ASSET DISPOSAL',
    //             link: '/assetmanagement/assetdisposal',
    //             parentId: 109,
    //         }
    //     ]
    // },
    // {
    //     id: 130,
    //     label: 'MENUITEMS.ECOMMERCE.TEXT',
    //     icon: 'bx-store',

    //     subItems: [
    //         {
    //             id: 12,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.LEVEL1.1',
    //             parentId: 130,
    //             subItems: [
    //                 {
    //                     id: 12,
    //                     label: 'MENUITEMS.ECOMMERCE.LIST.LEVEL1.LEVEL2.1',
    //                     link: '/ecommerce/admin/ecommercerateconfiguration',
    //                     parentId: 130,
    //                 },
    //                 {
    //                     id: 12,
    //                     label: 'MENUITEMS.ECOMMERCE.LIST.LEVEL1.LEVEL2.2',
    //                     link: '/ecommerce/admin/ecommerceratemaster',
    //                     parentId: 130,
    //                 },
    //                 {
    //                     id: 12,
    //                     label: 'MENUITEMS.ECOMMERCE.LIST.LEVEL1.LEVEL2.3',
    //                     link: '/ecommerce/admin/eshoppingtoecommerce',
    //                     parentId: 130,
    //                 }
    //             ]
    //         },
    //     ]
    // },
    // {
    //     id: 2,
    //     label: 'MENUITEMS.DOCUMENTS.TEXT',
    //     icon: 'bx bx-book-content',
    //     // badge: {
    //     //     variant: 'info',
    //     //     text: 'MENUITEMS.DOCUMENTS.BADGE',
    //     // },
    //     subItems: [
    //         {
    //             id: 3,
    //             label: 'MENUITEMS.DOCUMENTS.LIST.TRAININGMATERIALS',
    //             link: '/documents/trainingmaterials',
    //             parentId: 2
    //         },
    //     ]
    // },
    // // {
    // //     id: 143,
    // //     label: 'MENUITEMS.MULTILEVEL.TEXT',
    // //     icon: 'bx-share-alt',
    // //     subItems: [
    // //         {
    // //             id: 144,
    // //             label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.1',
    // //             parentId: 143
    // //         },
    // //         {
    // //             id: 145,
    // //             label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.2',
    // //             parentId: 143,
    // //             subItems: [
    // //                 {
    // //                     id: 146,
    // //                     label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.1',
    // //                     parentId: 145,
    // //                 },
    // //                 {
    // //                     id: 147,
    // //                     label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.2',
    // //                     parentId: 145,
    // //                 }
    // //             ]
    // //         },
    // //     ]
    // // }
];

