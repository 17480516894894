<ng-template #contentTemplate>
  <div id="sidebar-menu">
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <li *ngFor="let item of menuItems; let i = index" [class.menu-title]="item.isTitle"
        [class]="item.isLayout ? 'layout' : ''">
        <ng-container *ngIf="item.isTitle">
          <span>{{ item.label | translate }}</span>
        </ng-container>
        <ng-container *ngIf="!item.isTitle && !item.isLayout">
          <ng-container *ngIf="hasItems(item)">
            <a href="javascript:void(0);" class="is-parent"
              [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}" (click)="toggleDropdown(i)">
              <i *ngIf="item.icon" class="bx {{item.icon}}"></i>
              <span> {{ item.label | translate }}</span>
              <span *ngIf="item.badge" class="badge rounded-pill float-end bg-{{item.badge.variant}}">
                {{item.badge.text | translate}}</span>
            </a>
            <ul *ngIf="dropdownIndex === i"  class="sub-menu" aria-expanded="true">
              <ng-container *ngTemplateOutlet="recursiveList; context:{
                $implicit: item.subItems, parentIndex: i }"></ng-container>
            </ul>
          </ng-container>
          <ng-container *ngIf="!hasItems(item)" style="padding-right: 20px;">
            <a [routerLink]="item.link" class="side-nav-link-ref" routerLinkActive="active">
              <i *ngIf="item.icon" class="bx {{ item.icon }}"></i>
              <span> {{ item.label | translate }}</span>
              <span *ngIf="item.badge" class="badge rounded-pill bg-{{item.badge.variant}} float-end">
                {{item.badge.text | translate}}</span>
            </a>
          </ng-container>
        </ng-container>
      </li>
    </ul>
  </div>
</ng-template>
<ng-template #recursiveList let-list let-parentIndex="parentIndex">
  <li *ngFor="let subitem of list; let j = index">
    <a *ngIf="!hasItems(subitem)" [routerLink]="subitem.link" routerLinkActive="active" class="side-nav-link-ref">
      {{ subitem.label | translate }}
    </a>
    <ng-container *ngIf="hasItems(subitem)">
      <a href="javascript:void(0);" class="has-arrow" (click)="toggleSubmenu($event, parentIndex, j)">
        {{ subitem.label | translate }}
      </a>
      <ul *ngIf="isSubmenuOpen(parentIndex, j)" class="sub-menu" aria-expanded="false">
        <ng-container *ngTemplateOutlet="recursiveList; context:{
          $implicit: subitem.subItems, parentIndex: j }"></ng-container>
      </ul>
    </ng-container>
  </li>
</ng-template>


<!-- Left Sidebar End -->
<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>

