import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthfakeauthenticationService } from './core/services/authfake.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private authService: AuthfakeauthenticationService) { }

  ngOnInit() {
    // Listen for the logout event
    window.addEventListener('storage', (event) => {
      if (event.key === 'logout-event') {
        this.handleLogout();
      }
    });
  }

  handleLogout() {
    this.authService.logout();
    window.location.href=environment.signOutUrl;
  }
}
