import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges } from '@angular/core';
import MetisMenu from 'metismenujs';
import { EventService } from '../../core/services/event.service';
import { Router, NavigationEnd } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  openSubmenuIndex: { parentIndex: number; submenuIndex: number; }[] = [];
  @ViewChild('componentRef') scrollRef: ElementRef;
@Input() isCondensed = false;
menu: any;
dropdownIndex: number = -1;
// openSubmenuIndex: number[] = [];
menuItems: MenuItem[] = [];

@ViewChild('sideMenu') sideMenu: ElementRef;

constructor(private router: Router) {
  router.events.subscribe((event) => {
    if (event instanceof NavigationEnd) {
      this.activateMenuDropdown();
    }
  });
}

ngOnInit() {
  this.initialize();
  this.scrollElement();
}

ngAfterViewInit() {
  this.menu = new MetisMenu(this.sideMenu.nativeElement);
  this.activateMenuDropdown();
}

ngOnChanges() {
  if (!this.isCondensed && this.sideMenu || this.isCondensed) {
    setTimeout(() => {
      this.menu = new MetisMenu(this.sideMenu.nativeElement);
    });
  } else if (this.menu) {
    this.menu.dispose();
  }
}

scrollElement() {
  setTimeout(() => {
    const activeElement = document.querySelector('.mm-active');
    if (activeElement) {
      const currentPosition = activeElement['offsetTop'];
      if (currentPosition > 500 && this.scrollRef && this.scrollRef.nativeElement) {
        this.scrollRef.nativeElement.scrollTop = currentPosition + 300;
      }
    }
  }, 300);
}

activateMenuDropdown() {
  const currentPath = window.location.pathname;
  this.menuItems.forEach((item, index) => {
    if (item.link && currentPath.includes(item.link)) {
      this.activateMenuItem(index);
    }
  });
}

activateMenuItem(index: number) {
  const menuItemEl = document.querySelectorAll('.side-nav-link-ref')[index];
  if (menuItemEl) {
    menuItemEl.classList.add('active');
    let parentEl = menuItemEl.parentElement;
    while (parentEl && parentEl !== this.sideMenu.nativeElement) {
      if (parentEl.tagName === 'LI') {
        parentEl.classList.add('mm-active');
      }
      parentEl = parentEl.parentElement;
    }
  }
}

toggleDropdown(index: number) {
  this.dropdownIndex = this.dropdownIndex === index ? -1 : index;
}



// Updated toggleSubmenu method
toggleSubmenu(event: MouseEvent, parentIndex: number, submenuIndex: number) {
event.preventDefault();
const index = this.openSubmenuIndex.findIndex(item => item.parentIndex === parentIndex && item.submenuIndex === submenuIndex);
if (index !== -1) {
  this.openSubmenuIndex.splice(index, 1);
} else {
  this.openSubmenuIndex.push({ parentIndex, submenuIndex });
}
}

// Updated isSubmenuOpen method
isSubmenuOpen(parentIndex: number, submenuIndex: number): boolean {
return this.openSubmenuIndex.some(item => item.parentIndex === parentIndex && item.submenuIndex === submenuIndex);
}



initialize(): void {
  this.menuItems = MENU;
}

hasItems(item: MenuItem) {
  return item.subItems !== undefined && item.subItems.length > 0;
}
}