<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                Copyright © {{year}}. <a href="https://cooptex.gov.in/" target="_blank">Co-optex</a>
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block"><a href="https://www.oasys.co/"  target="_blank">
                    Powered by <img src="../../../assets/images/oasys-logo.png" width="36px">
                </a>
                </div>
            </div>
        </div>
    </div>
</footer>