export const environment = {
  production: true,
  defaultauth: 'fakebackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  baseUrl: 'https://portalapi.infotex.in/portalapi/',
  portalUrl: 'https://infotex.in/portal/pages/home.xhtml',
  financeUrl: 'https://infotex.in/finance/pages/welcomeInfotex.xhtml',
  misReportUrl: 'https://infotex.in/mis/pages/welcomeInfotex.xhtml',
  helpDeskUrl: 'https://infotex.in/helpdesk/pages/welcomeInfotex.xhtml',
  signOutUrl: 'https://infotex.in/portal/signout.xhtml'
};
