import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})


export class LeaverequestService {
  baseUrls = (environment as any).baseUrl;
  constructor(private http: HttpClient) { }

  getData(request): Observable<any> {
    const url = this.baseUrls + "customdata/getdata";
    return this.http.post(url, request);
  }


  postData(data): Observable<any> {
    let headers = new HttpHeaders;
    const url = this.baseUrls + 'leave-requests/addLeaveRequest';
    return this.http.post(url, data, { headers: headers });

  }

  approveRequest(req): Observable<any> {
    const url = this.baseUrls + 'leave-requests/approveLeaveRequest';
    return this.http.post(url, req);
  }

  checkExistingLeaveRequests(req): Observable<any> {
    const url = this.baseUrls + 'leave-requests/validateFromDateAndToDate';
    return this.http.post(url, req);
  }


  pdfFileDownload(data: any, options: any) {
    const fullUrl = `${this.baseUrls}leave-requests/download`;
    return this.http.post(fullUrl, data, options);
  }

}

