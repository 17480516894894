import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/store/Authentication/auth.models';

@Injectable({ providedIn: 'root' })
export class AuthfakeauthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        const storedUser = localStorage.getItem('currentUser');
        let initialUser: User | null = null;
        try {
            if (storedUser) {
                initialUser = JSON.parse(storedUser);
            }
        } catch (error) {
            console.error('Error parsing stored user data:', error);
        }
        this.currentUserSubject = new BehaviorSubject<User | null>(initialUser);
        this.currentUser = this.currentUserSubject.asObservable();
        // this.currentUserSubject = new BehaviorSubject<User>(JSON.parse('"'+localStorage.getItem('currentUser')+'"'));
        // this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {
        return this.http.post<any>(`/users/authenticate`, { email, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('empCode')
        localStorage.removeItem('currentUser');
        localStorage.removeItem('UserId');
        localStorage.removeItem('empPfNumber');
        localStorage.removeItem('empName');
        localStorage.removeItem('desgination');
        localStorage.removeItem('entityTypeMasterId')
        localStorage.removeItem('DepartmentID');
        localStorage.removeItem('headOrRegionOfficeId');
        localStorage.removeItem('empPfNumber');
        localStorage.removeItem('empName');
        localStorage.removeItem('EntityId');
        this.currentUserSubject.next(null);


        // Dispatch a logout event to all tabs
        localStorage.setItem('logout-event', 'logout' + Math.random());
    }
}
