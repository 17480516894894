import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Observable, interval, map, take } from 'rxjs';
import { changesLayout } from 'src/app/store/layouts/layout.actions';
import { getLayoutMode } from 'src/app/store/layouts/layout.selector';
import { RootReducerState } from 'src/app/store';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LeaverequestService } from 'src/app/service/leaverequest.service';
import { response } from 'express';
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',

  styleUrls: ['./topbar.component.scss'],
  providers: [BsModalService]
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  mode: any
  element: any;
  cookieValue: any;
  flagvalue: any;
  countryName: any;
  valueset: any;
  theme: any;
  layout: string;
  dataLayout$: Observable<string>;
  // Define layoutMode as a property
  modalRef?: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  name: any;

  // idleTimer$: Observable<number>;
  // idleTimeoutSeconds = 900; // Set your idle timeout duration in seconds


  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService, public store: Store<RootReducerState>, private modalService: BsModalService, private leaveService: LeaverequestService) {

  }

  listLang: any = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    //USED FOR AUTOMATICALLY LOGOUT
    // this.idleTimer$ = interval(1000).pipe(
    //   take(this.idleTimeoutSeconds),
    //   map(() => this.idleTimeoutSeconds--)
    // );

    // this.idleTimer$.subscribe(() => {
    //   if (this.idleTimeoutSeconds === 0) {
    //     this.logout(); // Call your logout method here
    //   }
    // });

    this.getEntityForUser();
    this.getEntityForUsers();
    // this.initialAppState = initialState;
    this.store.select('layout').subscribe((data) => {
      this.theme = data.DATA_LAYOUT;
    })
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
  }
  centerModal(centerDataModal: any) {
    this.modalRef = this.modalService.show(centerDataModal);
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else {
      this.authFackservice.logout();
    }
    // this.router.navigate(['/auth/login']);
  }




  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  changeLayout(layoutMode: string) {
    this.theme = layoutMode;
    this.store.dispatch(changesLayout({ layoutMode }));
    this.store.select(getLayoutMode).subscribe((layout) => {
      document.documentElement.setAttribute('data-layout', layout)
    })
  }
  getuserdetails: any[] = [];


  getEntityForUser() {
    const usernamepfnumber = localStorage.getItem('currentUser');
    const req = {
      dataCode: "GET_USER_DETAILS",
      placeholderKeyValueMap: {
        userName: "'" + usernamepfnumber + "'"
      }
    };
    this.leaveService.getData(req).subscribe(entityresponse => {
      if (entityresponse.statusCode == 0) {
        this.getuserdetails = entityresponse.responseContent;
        localStorage.setItem('empCode', this.getuserdetails[0].empcode)
        localStorage.setItem('desgination', this.getuserdetails[0].DesignationName);
        localStorage.setItem('entityTypeMasterId', this.getuserdetails[0].entity_type_id)
        localStorage.setItem('DepartmentID', this.getuserdetails[0].DepartmentID);
        localStorage.setItem('headOrRegionOfficeId', this.getuserdetails[0].EntityId);
        localStorage.setItem('empPfNumber', this.getuserdetails[0].pf_number);
        localStorage.setItem('empName', this.getuserdetails[0].first_name);
        localStorage.setItem('EntityId', this.getuserdetails[0].EntityId);
      }
      else {
        this.getuserdetails = [];

      }
    });
  }

  getEntityForUsers() {
    const usernamepfnumber = localStorage.getItem('currentUser');
    const req = {
      dataCode: "GET_USERNAME_ENTITY",
      placeholderKeyValueMap: {
        pfNumber: "'" + usernamepfnumber + "'"
      }
    };
    this.leaveService.getData(req).subscribe(data => {
      //console.log(req);
      if (data.statusCode == 0) {
        //console.log(this.users)
        localStorage.setItem("UserId", data.responseContent[0].userid);
        //console.log(this.usernamepfnumberid);
      } else {
        //console.log("ERROR");
      }
    });

  }

  // JsfPortal() {
  //   window.location.href = "https://staging.infotex.in/portal/pages/portalHome.xhtml";
  // }

  // portalJsf() {
  //   window.location.href ="https://staging.infotex.in/portal/pages/portalHome.xhtml";
  // }

  // financeJsf() {
  //   window.location.href ="https://staging.infotex.in/finance/pages/finance/finhome.xhtml";
  // }

  // misReportJsf() {
  //   window.location.href ="https://staging.infotex.in/mis/pages/home.xhtml";
  // }

  // helpDeskJsf() {
  //   window.location.href ="https://staging.infotex.in/helpdesk/pages/helpdesk/listTicket.xhtml";
  // }


  jsf() {
    window.location.href = environment.signOutUrl;
  }

  portalJsf() {
    window.location.href = environment.portalUrl;
  }

  financeJsf() {
    window.location.href = environment.financeUrl;
  }

  misReportJsf() {
    window.location.href = environment.misReportUrl;
  }

  helpDeskJsf() {
    window.location.href = environment.helpDeskUrl;
  }


}